import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image/compat"
// Hooks
import { useImageSrc } from "../../hooks/useImageSrc"
// Components
import {Button} from "../../components";
// Styles
import "./mainServices.sass"

import { SERVICES_ROUTE } from "../../constants/routes"

export const MainServices = () => {
  const { src, srcSet } = useImageSrc()

  const services = [
    { title: "Татуаж", path: "/tattoo", name: "tattoo" },
    { title: "Маникюр", path: "/manicure", name: "manicure" },
    { title: "Педикюр", path: "/foot-care", name: "footCare" },
    { title: "Удаление", path: "/removing", name: "removing" },
    {
      title: "Оформление бровей и ресниц",
      path: "/eyelashes",
      name: "eyelashes",
    },
    {
      title: "Аппаратная косметология",
      path: "/cosmetology",
      name: "cosmetology",
    },
  ]

  const calculateDirection = index => {
    if (typeof window !== "undefined") {
      if (window.innerWidth >= 1200) {
        if (index === 1 || index === 4) return "slide-down"
        if (index === 2 || index === 5) return "slide-left"
        if (index % 3 < 1) return "slide-right"
      } else {
        if (index % 2 < 1) return "slide-right"
        if (index % 2 > 0) return "slide-left"
      }
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          tattoo: file(relativePath: { eq: "assets/img/tattoo.png" }) {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 550, quality: 70) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          manicure: file(relativePath: { eq: "assets/img/маникюр.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 550, quality: 70) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          footCare: file(relativePath: { eq: "assets/img/педикюр.png" }) {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 550, quality: 70) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          removing: file(relativePath: { eq: "assets/img/удаление.png" }) {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 550, quality: 70) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          eyelashes: file(relativePath: { eq: "assets/img/ресницы.png" }) {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 550, quality: 70) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          cosmetology: file(
            relativePath: { eq: "assets/img/аппаратная-косметология.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 550, quality: 70) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <section className="services">
            <h2>Популярные Услуги</h2>
            <div className="cards">
              {services.map((service, index) => {
                const direction = calculateDirection(index)
                const fluid = data[service.name].childImageSharp.fluid

                return (
                  <div
                    data-sal={direction}
                    data-sal-duration="700"
                    className="card"
                    data-sal-easing="linear"
                    key={index}
                  >
                    <h3>{service.title}</h3>
                    <Link to={`${SERVICES_ROUTE}${service.path}`}>
                      <div className="img ">
                        {src && fluid && (
                          <GatsbyImage
                            loading="lazy"
                            placeholder={{ fallback: fluid.base64 }}
                            images={{
                              fallback: {
                                src: fluid.src,
                                srcSet: fluid.srcSet,
                              },
                              sources: [
                                {
                                  src: fluid[src],
                                  srcSet: fluid[srcSet],
                                },
                              ],
                            }}
                            alt={service.name}
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                )
              })}
            </div>
            <Button to="/services" kind="transform" style={{marginTop: '75px'}}>Все Услуги</Button>
          </section>
        )
      }}
    />
  )
}

export default MainServices

import { useState, useEffect } from "react"

export const useImageSrc = () => {
  const [src, setSrc] = useState({ src: "", srcSet: "" })

  useEffect(() => {
    if (typeof window !== undefined) {
      const IOS = ["iPad", "iPod", "iPhone"]

      if (IOS.includes(window.navigator.platform)) {
        setSrc({ src: "src", srcSet: "srcSet" })
      } else {
        setSrc({ src: "srcWebp", srcSet: "srcSetWebp" })
      }
    }
  }, [])

  return src
}

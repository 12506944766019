import React from "react"

import {
  CONTACTS_ROUTE,
  HOME_ROUTE,
  PRICE_ROUTE,
  SERVICES_ROUTE,
} from "../../../constants/routes"
import { Link } from "gatsby"

export const Menu = ({ isVisible = true, toggleMenu }) => {

  return (
    <>
      {isVisible ? (
        <nav className="menu">
          <Link
            to={`${HOME_ROUTE}`}
            activeClass={'test'}
            activeStyle={{ fontWeight: 700 }}
            onClick={toggleMenu ? toggleMenu : undefined}
          >
            Главная
          </Link>
          <Link
            isPartiallyCurrent={true}
            to={SERVICES_ROUTE}
            getProps={({ isPartiallyCurrent }) =>
              isPartiallyCurrent ? { className: "active" } : null
            }
            onClick={toggleMenu ? toggleMenu : undefined}
          >
            Услуги
          </Link>
          <Link
            to={PRICE_ROUTE}
            getProps={({ isPartiallyCurrent }) =>
              isPartiallyCurrent ? { className: "active" } : null
            }
            onClick={toggleMenu ? toggleMenu : undefined}
          >
            Цены
          </Link>
          <Link
            to={CONTACTS_ROUTE}
            getProps={({ isPartiallyCurrent }) =>
              isPartiallyCurrent ? { className: "active" } : null
            }
            onClick={toggleMenu ? toggleMenu : undefined}
          >
            Контакты
          </Link>
        </nav>
      ) : null}
    </>
  )
}

import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image/compat"
// Images
import insta from "../../assets/img/svgs/instagram-dark.svg"
// Styles
import "./gallery.sass"

export const Gallery = () => {
  const [mouseEnter, setMouseEnter] = useState(false)

  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            allInstaNode(limit: 16, sort: { fields: timestamp, order: DESC }) {
              edges {
                node {
                  localFile {
                    childImageSharp {
                      fixed(width: 200, height: 200, quality: 60) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                  caption
                  id
                }
              }
            }
          }
        `}
        render={data => {
          const {
            allInstaNode: { edges },
          } = data

          return (
            <section className="gallery">
              <h2>Фотогалерея</h2>
              <div className="photos">
                {edges.map((edge, index) => {
                  if (!edge.node.localFile) return null
                  const fluid = edge.node.localFile.childImageSharp.fixed

                  return (
                    <a
                      href={`https://instagram.com/p/${edge.node.id}`}
                      className="link"
                      rel="noopener noreferrer"
                      target="_blank"
                      key={index}
                    >
                      <div
                        role="presentation"
                        data-sal="zoom-in"
                        data-sal-duration="700"
                        style={{ width: "100%", height: "100%" }}
                        title={mouseEnter ? edge.node.caption : ""}
                        onMouseEnter={() => setMouseEnter(true)}
                        onMouseLeave={() => setMouseEnter(false)}
                      >
                        {fluid && (
                          <GatsbyImage
                            className="photo"
                            placeholder={{ fallback: fluid.base64 }}
                            images={{
                              fallback: {
                                src: fluid.src,
                                srcSet: fluid.srcSet,
                              },
                              sources: [
                                {
                                  src: fluid.src,
                                  srcSet: fluid.srcSet,
                                },
                              ],
                            }}
                            alt={"фото работы"}
                          />
                        )}
                      </div>
                    </a>
                  )
                })}
              </div>
              <a
                href="https://www.instagram.com/lab.beauty"
                rel="noopener noreferrer"
                target="_blank"
                className="lab"
              >
                <img src={insta} alt="instagram" /> Открыть instagram
              </a>
            </section>
          )
        }}
      />
    </>
  )
}

export default Gallery

import React from 'react'
import { graphql, StaticQuery, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image/compat"
// Hooks
import { useImageSrc } from "../../hooks/useImageSrc"
// Constants
import { services} from "../../constants/services"
import {SERVICES_ROUTE} from "../../constants/routes";
// Styles
import styles from './relativeServices.module.sass'

export const RelativeServices = ({currentService}) => {
  const { src, srcSet } = useImageSrc()

  const availableServices = services.filter(service => service.title !== currentService)
  return (
    <StaticQuery
      query={graphql`
        query {
          footCare: file(
            relativePath: { eq: "assets/img/педикюр.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          manicure: file(
            relativePath: { eq: "assets/img/маникюр.jpg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          tattoo: file(
            relativePath: { eq: "assets/img/tattoo.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          microcurrentTherapy: file(
            relativePath: {
              eq: "assets/img/services/microcurrent-therapy.jpeg"
            }
          ) {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          laserPigment: file(
            relativePath: { eq: "assets/img/services/laser-pigment.jpeg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          carbonPeeling: file(
            relativePath: { eq: "assets/img/services/carbon-peeling.jpeg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          ultraphonophoresis: file(
            relativePath: { eq: "assets/img/services/ultraphonophoresis.jpeg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          cosmetology: file(
            relativePath: { eq: "assets/img/services/cosmetology.jpeg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          eyelashes: file(
            relativePath: { eq: "assets/img/ресницы.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          removing: file(
            relativePath: { eq: "assets/img/services/removing.jpeg" }
          ) {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {

        return (
          <section className={styles.container}>
            <h2 className={styles.title}>Вас может заинтересовать</h2>
            <div className={styles.relativeServices}>
              {availableServices && availableServices.slice(0, 3).map((service, i) => {
                const fluid = data[service.name].childImageSharp.fluid

                return (
                  <Link
                    to={`${SERVICES_ROUTE}/${service.route}`}
                    className={styles.link}
                    key={i}>
                      {src && fluid && (
                        <div className={styles.imageContainer}>
                          <GatsbyImage
                            className={styles.image}
                            placeholder={{fallback: fluid.base64}}
                            images={{
                              fallback: {
                                src: fluid.src,
                                srcSet: fluid.srcSet,
                              },
                              sources: [
                                {
                                  src: fluid[src],
                                  srcSet: fluid[srcSet],
                                },
                              ],
                            }}
                            alt={service.name}
                          />
                        </div>
                      )}

                      <h3 className={styles.title}>{service.title}</h3>
                  </Link>
                )
              })}
            </div>
          </section>
        )
      }}
    />
  )
}
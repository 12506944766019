import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
// Constants
import { HOME_ROUTE } from "../../constants/routes"
// Components
import { Menu } from "./Menu"
// Images
import Logo from "../../assets/img/svgs/logo-dark.inline.svg"
import tel from "../../assets/img/svgs/tel.svg"
// Styles
import "../../assets/styles/hamburger.css"
import "./header.sass"

export const Header = ({color='#41444E'}) => {
  const [isActive, setActive] = useState(false)
  const toggleMenu = () => setActive(!isActive)

  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
  }, [isActive])

  return (
    <>
      <header
        className="header"
        style={{ backdropFilter: isActive ? "blur(0)" : "blur(3px)" }}
      >
        <div className="logo">
          <Link to={HOME_ROUTE}>
            {/*<img src={logo} alt="logo_dark" />*/}
            <Logo style={{color: color}} />
          </Link>
        </div>

        <div className="desktop-menu">
          <Menu />
        </div>

        <div className="desktop-tel">
          <img src={tel} alt="tel" />
          &nbsp;&nbsp;
          <a href="tel:+380962279487">+38 (096) 227-94-87</a>
        </div>

        <button
          aria-label="burger"
          type="button"
          className={`hamburger hamburger--spin js-hamburger ${
            isActive && "is-active"
          }`}
          onClick={toggleMenu}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" style={{background: isActive ? 'black' : color}} />
          </span>
        </button>
      </header>
      <div className={`mobile-menu ${isActive && "active"}`}>
        <h2>Меню</h2>
        <Menu toggleMenu={toggleMenu} />
        <div className="tel">
          <img src={tel} alt="tel" />
          &nbsp;
          <a href="tel:+380962279487">+38 (096) 227-94-87</a>
        </div>
      </div>
    </>
  )
}

import React from 'react'
import { Link } from "gatsby"
// Styles
import styles from './button.module.sass'

export const Button = ({to, type, kind, style, children}) => {
  const Element = to ? Link : 'button'

  return (
    <Element
      {...to && {to: to}}
      {...type && {type:type}}
      style={style}
      className={`${styles.button} ${styles[kind || 'default']}`}>{children}</Element>
  )
}
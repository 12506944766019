import React from "react"
// import {graphql, StaticQuery, withPrefix} from "gatsby"
import {withPrefix} from "gatsby"
// import { GatsbyImage } from "gatsby-plugin-image/compat"
// Hooks
// import { useImageSrc } from "../../hooks/useImageSrc"
// Images
import facebook from "../../assets/img/svgs/facebook-dark.svg"
import instagram from "../../assets/img/svgs/instagram-dark.svg"
import viber from "../../assets/img/svgs/viber-dark.svg"
import poster from "../../assets/img/work.jpg"
// Styles
import "./hero.sass"

export const Hero = () => {
  // const { src, srcSet } = useImageSrc()

  {/*  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "assets/img/hero.png" }) {
            childImageSharp {
              fluid(maxWidth: 500, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        const fluid = data.file.childImageSharp.fluid*/}

        return (
          <section className="hero" id="hero">
            <div className="wrapper">

              <video
                autoPlay
                muted
                loop
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  height: '100%',
                  width: '100%',
                  zIndex: '-1',
                  objectFit: 'cover',
                  objectPosition: 'center'
                }}
                poster={poster}
              >
                <source src={withPrefix('/work.mp4')} type="video/mp4" />
                Your device does not support playing 'video/mp4' videos
              </video>

 {/*             <div className="img">
                {src && fluid && false && (
                  <GatsbyImage
                    loading="eager"
                    placeholder={{ fallback: fluid.base64 }}
                    images={{
                      fallback: {
                        src: fluid.src,
                        srcSet: fluid.srcSet,
                      },
                      sources: [
                        {
                          src: fluid[src],
                          srcSet: fluid[srcSet],
                        },
                      ],
                    }}
                    alt="hero"
                  />
                )}
              </div>*/}
              <div className="content">
                <div>
                  <div className="text" data-sal="slide-down" data-sal-easing="linear" data-sal-duration="700">
                    <h1>
                      Студия красоты "Lab Beauty"
                      <br />
                      Ирины Лучистой
                    </h1>
                    <p>
                      Мы не делаем Вас красивыми, мы лишь подчеркиваем то, что
                      уже дала Вам природа.
                    </p>
                  </div>
                  <div className="nets" data-sal="zoom-in" data-sal-easing="linear" data-sal-delay="200" data-sal-duration="500">
                    <a
                      href="viber://add?number=380962279487"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={viber} alt="viber" />
                    </a>
                    <a
                      href="https://instagram.com/lab.beauty"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={instagram} alt="instagram" />
                    </a>
                    <a
                      href="https://facebook.com/iryna.luchistaya"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={facebook} alt="facebook" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
  //     }}
  //   />
  // )
}
import React from "react"

import { ContactUsForm, Map } from "./"
import "./mapWithForm.sass"

export const MapWithForm = () => {
  return (
    <section className="contact-us" id="contact-us">
      <Map />
      <ContactUsForm />
    </section>
  )
}

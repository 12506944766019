import React from 'react'
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image/compat"
// Hooks
import { useImageSrc } from "../../hooks/useImageSrc"
// Components
import {Button} from "../Button/Button";
// Styles
import styles from './stillQuestions.module.sass'

export const StillQuestions = () => {
  const { src, srcSet } = useImageSrc()

  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "assets/img/questions.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 500, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        const fluid = data.file.childImageSharp.fluid

        return (
          <section className={styles.container}>
            <div className={styles.image}>
                {src && fluid && (
                  <GatsbyImage
                    placeholder={{ fallback: fluid.base64 }}
                    images={{
                      fallback: {
                        src: fluid.src,
                        srcSet: fluid.srcSet,
                      },
                      sources: [
                        {
                          src: fluid[src],
                          srcSet: fluid[srcSet],
                        },
                      ],
                    }}
                    alt="hero"
                  />
                )}
              </div>
            <div className={styles.content}>
                <h2
                  className={styles.title}
                  data-sal="slide-down"
                  data-sal-duration="1000">
                  Остались вопросы?
                </h2>
                <p
                  className={styles.description}
                  data-sal="slide-up"
                  data-sal-delay="100"
                  data-sal-duration="700">
                  Перейдите по ссылке ниже или <br/>
                  свяжитесь с нами: <a className={styles.tel} href="tel:+380962279487">+38 (096) 227-94-87</a>
                </p>
                <div
                  data-sal="zoom-in"
                  data-sal-duration="700"
                  data-sal-delay="200">
                  <Button
                    to="/contacts"
                    style={{marginTop: '40px'}}
                    kind="transform">Связаться с Нами</Button>
                </div>
              </div>
          </section>
        )
      }}
    />
  )
}
import axios from "axios"
import React, { createRef, useState } from "react"
// Styles
import "./Form.sass"

export const ContactUsForm = () => {
  const form = createRef()

  const [values, setValues] = useState({ name: "", phone: "", message: "" })
  const [errors, setErrors] = useState({ name: "", phone: "", message: "" })

  const onChange = e => {
    const name = e.target.name
    const value = e.target.value
    setValues({ ...values, [name]: value })
    setErrors({ ...errors, [name]: "" })
  }

  const onKeyPress = event => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)
    // if (/[+]/g.test(keyValue)) {
    //   return;
    // }
    if (/\D+/g.test(keyValue)) {
      event.preventDefault()
    }
    if (event.target.value.length > 9) {
      event.preventDefault()
    }
  }

  const validateValues = () => {
    const err = {}

    if (values.name.length === 0) {
      err.name = "Имя не может быть пустым"
    }

    if (values.phone.length === 0) {
      err.phone = "Введите номер телефона"
    }

    if (values.phone.length > 0 && values.phone.length !== 10) {
      err.phone = "Введите номер в формате 0631111111"
    }

    if (values.message.length === 0) {
      err.message = "Пожалуйста, оставьте сообщение"
    }

    return err
  }

  const handleSubmit = event => {
    event.preventDefault()

    const currentErrors = validateValues()

    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors)
      return
    }

    axios
      .post(
        "https://vd0j0fe5vb.execute-api.us-east-1.amazonaws.com/live",
        {
          text: `\n<b>Имя: </b>${values.name} \n<b>Тел: </b>38${values.phone} \n<b>Cообщение: </b> ${values.message}`,
        },
        { headers: { Accept: "application/json" } }
      )
      .then(() => {
        if (form && form.current) {
          form.current.reset();
        }
        alert("Спасибо, Ваше сообщение отправлено")
      })
  }

  return (
    <section className="contact-us-form">
      <form name="contact-us" ref={form}>
        <h2>Связаться с Нами</h2>
        <div className="name">
          <label htmlFor="name">
            Имя:
            <input
              id="name"
              type="text"
              className={`${errors.name && "invalid"}`}
              placeholder="Имя"
              name="name"
              onChange={onChange}
            />
          </label>
          <p className="error">{errors.name}</p>
        </div>
        <div className="number">
          <label htmlFor="phone">
            Телефон:
            <input
              id="phone"
              type="text"
              className={`${errors.phone && "invalid"}`}
              placeholder="Номер телефона"
              name="phone"
              onKeyPress={onKeyPress}
              onChange={onChange}
            />
          </label>
          <p className="error">{errors.phone}</p>
        </div>
        <div className="message">
          <label htmlFor="message">
            Сообщение:
            <textarea
              id="message"
              name="message"
              className={`${errors.message && "invalid"}`}
              cols={10}
              rows={2}
              placeholder="Сообщения"
              onChange={onChange}
            />
          </label>
          <p className="error">{errors.message}</p>
        </div>
        <button type="button" onClick={handleSubmit}>
          Отправить
        </button>
      </form>
    </section>
  )
}

import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image/compat"
// Hooks
import { useImageSrc } from "../../hooks/useImageSrc"
// Styles
import "./aboutUs.sass"

export const AboutUs = () => {
  const { src, srcSet } = useImageSrc()

  return (
    <StaticQuery
      query={graphql`
        query {
          markdownRemark(frontmatter: { templateKey: { eq: "about-us" } }) {
            html
          }

          front: file(relativePath: { eq: "assets/img/owner.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          back: file(relativePath: { eq: "assets/img/owner-serious.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <section className="about-us">
            <div className="content">
              <div
                  data-sal="slide-left"
                  data-sal-duration="700"
                  data-sal-easing="linear">
                <div
                  dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
                />
              </div>
            </div>
            <div className="flip-box">
              <div className="flip-box-inner">
                <div className="flip-box-front">
                  {src && (
                    <GatsbyImage
                      loading="lazy"
                      placeholder={{
                        fallback: data.front.childImageSharp.fluid.base64,
                      }}
                      images={{
                        fallback: {
                          src: data.front.childImageSharp.fluid[src],
                          srcSet: data.front.childImageSharp.fluid[srcSet],
                        },
                        sources: [
                          {
                            src: data.front.childImageSharp.fluid[src],
                            srcSet: data.front.childImageSharp.fluid[srcSet],
                          },
                        ],
                      }}
                      style={{ height: "90%" }}
                      alt="front"
                    />
                  )}
                </div>
                <div className="flip-box-back">
                  {src && (
                    <GatsbyImage
                      loading="lazy"
                      placeholder={{
                        fallback: data.back.childImageSharp.fluid.base64,
                      }}
                      images={{
                        fallback: {
                          src: data.back.childImageSharp.fluid.src,
                          srcSet: data.back.childImageSharp.fluid.srcSet,
                        },
                        sources: [
                          {
                            src: data.back.childImageSharp.fluid[src],
                            srcSet: data.back.childImageSharp.fluid[srcSet],
                          },
                        ],
                      }}
                      style={{ height: "90%" }}
                      alt="back"
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        )
      }}
    />
  )
}

export default AboutUs

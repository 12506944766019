import React from "react"
// import LazyLoad from 'react-lazyload';
// @ts-ignore
// import Fade from 'react-reveal/Fade';

// import { distance } from '../../../constants/scrollReveal';

import Email from "../../../assets/img/svgs/email.svg"
import Phone from "../../../assets/img/svgs/phone.svg"
import Pin from "../../../assets/img/svgs/pin.svg"
import Time from "../../../assets/img/svgs/time.svg"

import "./map.sass"

export const Map = () => {
  return (
    <section className="map">
      <iframe
        title={"map"}
        src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d4519.7506603028405!2d30.787445730393582!3d50.52256229594977!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m0!4m5!1s0x40d4d97516ac9bd7%3A0xe4c7563b60b133ef!2sLab+Beauty+Studio+by+Irina+Luchistaya%2C+Vulytsya+Vasylya+Symonenka%2C+117%2C+Brovary%2C+Kyivs&#39;ka+oblast%2C+07400!3m2!1d50.523135599999996!2d30.7876747!5e0!3m2!1sen!2sua!4v1522612981538"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen={false}
      />
      {/*<Fade distance={distance} left={true}>*/}
      <div className="container">
        <div className="container__dest">
          <p>
            <img src={Pin} alt="pin" />
            <span>г. Бровары, ул. Симоненка, 117</span>
          </p>
          <p>
            <img src={Phone} alt="phone" />
            <span>+38 (096) 227-94-87</span>
          </p>
          <p>
            <img src={Email} alt="email" />
            <span>Lab.beauty.studio@gmail.com</span>
          </p>
          <p>
            <img src={Time} alt="time" />
            <span>10:00 - 21:00, ежедневно.</span>
          </p>
        </div>
      </div>
      {/*</Fade>*/}
    </section>
  )
}

export const services = [
  {
    name: "manicure",
    title: "Маникюр",
    route: "manicure",
  },
  {
    route: "foot-care",
    name: "footCare",
    title: "Педикюр",
  },
  {
    route: "tattoo",
    name: "tattoo",
    title: "Татуаж",
  },
  {
    route: "eyelashes",
    name: "eyelashes",
    title: "Оформление бровей и ресниц",
  },
  {
    route: "microcurrent-therapy",
    title: "Микротоковая терапция",
    name: "microcurrentTherapy",
  },
  {
    route: "laser-pigment",
    name: "laserPigment",
    title: "Лазерное осветление пигментных пятен",
  },
  {
    route: "carbon-peeling",
    name: "carbonPeeling",
    title: "Лазерный карбоновый пилинг",
  },
  {
    route: "ultraphonophoresis",
    name: "ultraphonophoresis",
    title: "Ультрафонофорез",
  },
  {
    route: "cosmetology",
    name: "cosmetology",
    title: "Аппаратная косметология",
  },
  {
    route: "removing",
    name: "removing",
    title: "Удаление татуажа",
  },
]
const BASE_URL = "/"

export const HOME_ROUTE = `${BASE_URL}`
export const SERVICES_ROUTE = `${BASE_URL}services`
export const PRICE_ROUTE = `${BASE_URL}price`
export const FAQ_ROUTE = `${BASE_URL}faq`
export const CONTACTS_ROUTE = `${BASE_URL}contacts`
export const COOKIE_ROUTE = `${BASE_URL}cookies`
export const BLOG_ROUTE = `${BASE_URL}blog`

export const MANICURE_SERVICE_ROUTE = `${SERVICES_ROUTE}/manicure`

import React from "react"
import { Helmet } from "react-helmet"
import { Header, Footer } from "../components"

export const Layout = ({ title, description, keywords, headerColor, children }) => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Helmet>
        <html lang="ru" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta
          name="keywords"
          content={keywords}
        />
      </Helmet>

      <Header color={headerColor} />
      {children}
      <Footer />
    </div>
  )
}

import React from "react"
import { Link } from "gatsby"

import { HOME_ROUTE } from "../../constants/routes"

import "./footer.sass"

import facebook from "../../assets/img/svgs/facebook.svg"
import instagram from "../../assets/img/svgs/instagram.svg"
import logo from "../../assets/img/svgs/logo-white.svg"
import viber from "../../assets/img/svgs/viber.svg"

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="content">
        <div className="logo">
          <Link to={HOME_ROUTE}>
            <img src={logo} alt="logo-white" />
          </Link>
        </div>

        <div
          className="copy"
          data-sal="slide-down"
          data-sal-duration="1000"
          data-sal-delay="0"
        >
          <span className="name">
            Студия "Lab Beauty" Ирины Лучистой © 2020.&nbsp;
          </span>{" "}
          <br />
          <span className="info">Все права защищены.</span>
        </div>

        <div
          className="nets"
          data-sal="slide-left"
          data-sal-duration="1000"
          data-sal-delay="0"
        >
          <a
            href="viber://add?number=380962279487"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={viber} alt="viber" />
          </a>
          <a
            href="https://instagram.com/lab.beauty"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="instagram" />
          </a>

          <a
            href="https://facebook.com/iryna.luchistaya"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="facebook" />
          </a>
        </div>
      </div>
    </footer>
  )
}
